
window._ = require('lodash');
window.Popper = require('popper.js').default;
window.$ = window.jQuery = window.jquery = require('jquery');
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.moment = require('moment');

window.Vue = require('vue');

window.ApexCharts = require('apexcharts');

import { createApp } from 'vue'

window.createApp = createApp;

import VueApexCharts from "vue3-apexcharts";

window.VueApexCharts = VueApexCharts;

require('bootstrap');
require('datatables.net/js/jquery.dataTables');
require('datatables.net-bs4/js/dataTables.bootstrap4');
require('datatables.net-responsive/js/dataTables.responsive');
require('datatables.net-responsive-bs4/js/responsive.bootstrap4');
require('tempusdominus-bootstrap-4/build/js/tempusdominus-bootstrap-4');
require('bootstrap-select');
require("summernote/dist/summernote-bs4");
require("../plugins/summernote-list-styles/summernote-list-styles-bs4")


window.delay = async function (ms = 1000) {
    const res = await new Promise((res) => {
        setTimeout(() => {
            res(true);
        }, ms)
    })

    return res;
}

// sidebar scripts
$(document).ready(function () {
    $(".menu-dropdown-toggle").click(function () {
        let elem = $(this);
        elem = elem.parent();

        let hide = elem.find("div[class='menu-dropdown-items']");
        let show = elem.find("div[class='menu-dropdown-items show']");

        if (show.length) {
            $(show[0]).removeClass("show");
        } else if (hide.length) {
            $(hide[0]).addClass("show");
        }
    });
});

// tempus dattimepicker init
$.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
    icons: {
        time: 'fas fa-clock',
        date: 'fas fa-calendar',
        up: 'fas fa-arrow-up',
        down: 'fas fa-arrow-down',
        previous: 'fas fa-chevron-left',
        next: 'fas fa-chevron-right',
        today: 'fas fa-calendar-check-o',
        clear: 'fas fa-trash',
        close: 'fas fa-times'
    }
});

$(document).ready(function () {
    var dateTimePickerInput = $('.datetimepicker-input');

    $.each(dateTimePickerInput, function (indexInArray, valueOfElement) {
        var dateTimePicker = $(valueOfElement);

        dateTimePicker.datetimepicker({
            format: dateTimePicker.data('format'),
            date: moment(dateTimePicker.data('value'), dateTimePicker.data('format'))
        });
    });
});
// end tempus dattimepicker init

// datatables

window.datatable = null;

window.getDatatable = function getDatatable() {
    return window.datatable;
}

function initDatatables(url, datatables) {
    if (url) {
        axios.get(url)
            .then(function (response) {
                drawDatatables(response.data, datatables)
            });
    }
}

function drawDatatables(structure, datatables) {
    var searching = true;
    var paging = true;
    var ordering = true;
    var info = true;
    var scrollX = true;

    if (structure.options != null) {
        if (typeof structure.options.searching != 'undefined') {
            searching = structure.options.searching;
        }

        if (typeof structure.options.paging != 'undefined') {
            paging = structure.options.paging;
        }

        if (typeof structure.options.ordering != 'undefined') {
            ordering = structure.options.ordering;
        }

        if (typeof structure.options.info != 'undefined') {
            info = structure.options.info;
        }

        if (typeof structure.options.scrollX != 'undefined') {
            scrollX = structure.options.scrollX;
        }
    }

    window.datatable = datatables.DataTable({
        processing: true,
        serverSide: true,
        ordering: ordering,
        searching: searching,
        info: info,
        paging: paging,
        order: structure.options.default_sort,
        ajax: {
            url: structure.options.url,
            data: function data(d) {
                $("#datatables-filters :input").each(function () {
                    var name = $(this).attr('name');
                    var val = $(this).val();
                    d[name] = val;
                });
            }
        },
        scrollX: scrollX,
        response: true,
        columns: structure.datas
    });
}

$(document).ready(function () {
    let datatables = $(".datatables");
    $.each(datatables, function (indexInArray, valueOfElement) {
        datatable = $(valueOfElement);
        let url = datatable.data("datatable-structure-url");
        initDatatables(url, datatable);
        $('#datatables-filters :input').change(function (e) {
            window.datatable.draw();
        });
        $("#datatables-filters :input").on("change.datetimepicker", ({ date, oldDate }) => {
            window.datatable.draw();
        })
    });

});


// end datatables

// summernote
$('.summernote').summernote({
    minHeight: "300px",
    toolbar: [
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['strikethrough', 'superscript', 'subscript']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'listStyles', 'paragraph']],
        ['height', ['height']],
        ['table', ['table']],
    ],
    tabDisable: false,
    maxHeight: "400px"
});
// end summernote

// app

window.openModalDelete = function (elem) {
    let deleteUrl = $(elem).data("delete-url");
    $("#form-delete").attr("action", deleteUrl);
    $("#modal-delete").modal("show");
};
// end app

// sidebar
$("#sidebar-toggle").click(function (e) {
    $(".sidebar").addClass("show");
});

$("#sidebar-close-button").click(function (e) {
    $(".sidebar").removeClass("show");
});
// end sidebar

// bootstrap-select init

$(document).ready(function () {
    $('.selectpicker').selectpicker();
});

// end bootstrap-select
